import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import "./services/training/training.scss"
import FormPopup from "../components/popups/FormPopup"
import GalleryPopup from "../components/popups/GalleryPopup"
import EnrollCourse from "../components/offers/EnrollCourse"
import SliderImages from "../components/sliders/SliderImages"
import TextGlassCards from "../components/cards/TextGlassCard"
import DoubleBlockHead from "../components/offers/DoubleBlockHead/DoubleBlockHead"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuServices from "../components/sideBarMenu/SidebarMenuServices"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import {SEO} from "../components/seo";

const Training = ({ data, location }) => {
  const { strapiTrainingPage } = data

  const [formPopup, setFormPopup] = useState(false)

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const mapTextData = data => {
    return [
      {
        title: data.item1.title,
        subtitle: data.item1.subtitle,
      },
    ]
  }

  const mapOfferData = data => {
    return {
      title: data.title,
      inputHeader: data.inputHeader,
      price: data.price,
      subTitle: data?.priceForStudent ? data?.priceForStudent : null,
      text: data.subtitle,
    }
  }

  return (
    <div>
      <SEO title={strapiTrainingPage?.titleDesc?.pageTitle} description={strapiTrainingPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="trainingPage" location={location}>
        <DoubleBlock menu={<SidebarMenuServices setFormPopup={setFormPopup} />}>
          {
            // MAIN OFFER
            strapiTrainingPage?.offer && (
              <DoubleBlockHead
                setFormPopup={setFormPopup}
                data={strapiTrainingPage.offer}
              />
            )
          }

          {
            //TEXT CARD
            strapiTrainingPage?.item1 && (
              <TextGlassCards
                classAdd="textCard--one textCard--bigText m60_40"
                data={mapTextData(strapiTrainingPage)}
              />
            )
          }

          {
            // PHOTO SLIDER
            strapiTrainingPage?.slider && (
              <div className="m60_40">
                <SliderImages
                  data={strapiTrainingPage?.slider}
                  positionCenter={false}
                  setOpen={setPopUpGallery}
                  open={popUpGallery}
                  setGalleryElem={setGalleryElem}
                />
              </div>
            )
          }

          {
            // OFFER 2
            strapiTrainingPage?.offer2 && (
              <EnrollCourse
                classAdd="m140"
                data={mapOfferData(strapiTrainingPage.offer2)}
              />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpGallery && strapiTrainingPage?.slider && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={strapiTrainingPage?.slider}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiTrainingPage {
      offer {
        title
        subtitle
      }
      offer2 {
        title
        price
        priceForStudent
        subtitle
        inputHeader
        button
      }
      item1 {
        title
        subtitle
      }
      slider {
        url
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`

export default Training
