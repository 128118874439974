import * as React from "react"
import Form from "../form/Form"

export default function EnrollCourse(props) {
  const { data, classAdd } = props
  const title = data.title
  const subTitle = data.subTitle
  const price = data.price
  const subPrice = data.subPrice
  const text = data.text

  return (
    <div className={classAdd ? `${classAdd} enrollCourse` : "enrollCourse"}>
      <div className="enrollCourse__cont">
        <div className="enrollCourse__text-block">
          {title && <h2>{title}</h2>}
          {price && <span className="enrollCourse__price">{price}</span>}
          {subPrice && <span className="enrollCourse__price enrollCourse__subPrice">{subPrice}</span>}
          {subTitle && <p className="enrollCourse__subTitle">{subTitle}</p>}

          {text && <p className="enrollCourse__text">{text}</p>}
        </div>

        <Form data={data} classAdd={"enrollCourse__form"} />
      </div>
    </div>
  )
}
